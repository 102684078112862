import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/shadcn/card';
import { isAppSupported } from '@/utils/device';
import Divider from '@/components/ui/shared/Divider';
import GoogleLoginButton from '@/components/ui/login/buttons/GoogleLoginButton';
import BreezeLoginButton from '@/components/ui/login/buttons/BreezeLoginButton';
import LoginFooter from '@/components/ui/login/LoginFooter';
import BreezeEmailForm from '@/components/ui/login/BreezeEmailForm';
import PhantomBrowser from '@/components/ui/login/phantom/PhantomBrowser';
import PhantomDeepLink from '@/components/ui/login/phantom/PhantomDeepLink';
import MagicLinkEmailForm from './MagicLinkEmailForm';

function LoginButtons({
  setIsEmailForm,
}: {
  setIsEmailForm: Dispatch<SetStateAction<boolean>>;
}) {
  const [isSupportedDevice, setIsSupportedDevice] = useState(false);

  useEffect(() => {
    setIsSupportedDevice(isAppSupported());
  }, []);

  return (
    <>
      <GoogleLoginButton />
      {isSupportedDevice ? <PhantomDeepLink /> : <PhantomBrowser />}
      <BreezeLoginButton onClick={() => setIsEmailForm(true)} />
      <Divider />
      <MagicLinkEmailForm />
    </>
  );
}

export default function LoginCard() {
  const [isEmailForm, setIsEmailForm] = useState(false);

  return (
    <Card className="flex w-full max-w-[378px] flex-col items-center p-6">
      <CardHeader className="p-0 pb-4">
        <CardTitle className="p-0 text-base font-bold leading-normal sm:text-xl">
          {!isEmailForm ? 'Log In / Sign Up' : 'Log In with Breeze'}
        </CardTitle>
      </CardHeader>
      <CardContent className="flex w-full p-0">
        <div
          data-visible={!isEmailForm}
          className="flex w-full flex-col items-center gap-2 data-[visible=false]:hidden">
          <LoginButtons setIsEmailForm={setIsEmailForm} />
        </div>
        <div
          data-visible={isEmailForm}
          className="flex w-full flex-col items-center gap-2 data-[visible=false]:hidden">
          <BreezeEmailForm setIsEmailForm={setIsEmailForm} />
        </div>
      </CardContent>
      <CardFooter className="p-0 pt-4">
        <LoginFooter />
      </CardFooter>
    </Card>
  );
}
