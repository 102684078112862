import LoginCard from '@/components/ui/login/LoginCard';
import WalletContextProvider from '@/components/ui/login/phantom/WalletContextProvider';
import { LayoutWithHeader } from '@/components/layout/Layout';
import useBreezeLogin from '@/hooks/useBreezeLogin';

export default function Login() {
  useBreezeLogin();

  return (
    <LayoutWithHeader>
      <div className="flex h-full flex-1 items-center justify-center">
        <WalletContextProvider>
          <LoginCard />
        </WalletContextProvider>
      </div>
    </LayoutWithHeader>
  );
}
