import { useState } from 'react';
import { useRouter } from 'next/router';
import { toast } from 'sonner';
import { VariantProps } from 'class-variance-authority';
import { Input } from '@/components/shadcn/input';
import { validateEmail } from '@/utils/form';
import { postToMagicLink } from '@/api/auth';
import * as Toast from '@/components/ui/toast/Toast';
import Error from '@/components/ui/errors/Error';
import { Button, buttonVariants } from '@/components/shadcn/button';

export default function MagicLinkEmailForm() {
  const [emailAddress, setEmailAddress] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);

  const router = useRouter();
  const {
    query: { redirectTo },
  } = router;
  const submitEmailForMagicLink = async (email: string) => {
    if (validateEmail(email)) {
      setIsEmailValid(true);
      const response = await postToMagicLink(email, redirectTo as string);

      if ('error' in response || 'message' in response) {
        const isError = 'error' in response;
        setEmailAddress('');

        toast.custom(t => {
          const title = isError ? 'Request Failed' : 'Email Verification';
          const description = isError
            ? 'Resubmit your email or refresh the page and try again.'
            : 'Check your inbox for a verification link to get started.';

          const dismissButton = {
            label: 'Dismiss',
            onClick: () => toast.dismiss(t),
            variant: 'secondary' as VariantProps<
              typeof buttonVariants
            >['variant'],
            size: 'fw' as VariantProps<typeof buttonVariants>['size'],
          };

          const refreshButton = {
            label: 'Refresh',
            onClick: () => location.reload(),
            variant: 'default' as VariantProps<
              typeof buttonVariants
            >['variant'],
            size: 'fw' as VariantProps<typeof buttonVariants>['size'],
          };

          return (
            <Toast.Default
              title={title}
              description={description}
              buttons={
                isError ? [dismissButton, refreshButton] : [dismissButton]
              }
            />
          );
        });
      }
    } else {
      setIsEmailValid(false);
    }
  };

  return (
    <>
      Email Address
      <Input
        ref={input => input?.focus()}
        type="email"
        className="border-neutral-800 bg-neutral-800 text-xs placeholder:text-neutral-700 focus:outline-neutral-700 focus-visible:ring-neutral-700 sm:text-base"
        value={emailAddress}
        autoFocus
        onKeyDown={e => {
          if (e.key === 'Enter') {
            submitEmailForMagicLink(emailAddress);
          }
        }}
        onChange={e => {
          setEmailAddress(e.target.value);
          setIsEmailValid(true);
        }}
      />
      <Error visible={!isEmailValid}>Please enter a valid email address</Error>
      <Button
        size="fw"
        variant="default"
        className="text-xs sm:text-base"
        onClick={() => {
          submitEmailForMagicLink(emailAddress);
        }}>
        Sign In
      </Button>
    </>
  );
}
