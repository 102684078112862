import React from 'react';

export default function Divider() {
  return (
    <div className="flex w-full items-center text-xs sm:text-base">
      <div className="grow border-t border-neutral-700"></div>
      <span className="mx-4">or</span>
      <div className="grow border-t border-neutral-700"></div>
    </div>
  );
}
