import Image from 'next/image';
import { useRouter } from 'next/router';
import { Button } from '@/components/shadcn/button';
import { DEFAULT_CLIENT_PATH } from '@/utils/url';

export default function GoogleLoginButton() {
  const router = useRouter();
  const { redirectTo } = router.query;
  const queryParamRedirect = `?redirectTo=${redirectTo || DEFAULT_CLIENT_PATH}`;

  return (
    <Button
      variant="secondary"
      size="fw"
      className="w-full justify-start gap-2 text-xs sm:text-base"
      onClick={() =>
        router.push(
          `${process.env.NEXT_PUBLIC_API}/login/google${queryParamRedirect}`,
        )
      }>
      <Image
        src="/images/logos/google.svg"
        width={16}
        height={17}
        alt="Google logo"
      />
      Continue with Google
    </Button>
  );
}
